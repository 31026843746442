import NextHead from "next/head";
import type { AppProps } from "next/app";
import { PageProps } from "frontend/types";

export default function App({ Component, pageProps }: AppProps<PageProps>) {
  const alternateLinks = pageProps.currentPage?.alternateLinks || [];
  return (
    <>
      <NextHead>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" type="image/ico" href="/static-assets/favicon.ico" />

        {Object.values(alternateLinks).map((link) => (
          <link
            key={link.hrefLang}
            rel="alternate"
            hrefLang={link.hrefLang}
            href={link.href}
          />
        ))}
      </NextHead>

      <Component {...pageProps} />
    </>
  );
}
